<template>
    <div class="modal fade" id="modalDetailOrder" tabindex="-1" role="dialog" aria-labelledby="modalDireccion" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content" style="padding: 0px 15px;">
                <div class="modal-header">
                    <div class="row h-100 p-0 w-100 text-center">
                        <div class="col-md-12 my-auto title-ps pt-2 title-modal" style="font-size: 23px; color: #053884;"><b>Detalle del pedido</b></div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="col-md-12">
                        <table class="table table-hover table-striped table-condensed table-bordered table-mobile">
                            <thead>
                                <th class="text-center">Tamaño</th>
                                <th class="text-center">Formato</th>
                                <th class="text-center">Calidad</th>
                                <th class="text-center">Faz</th>
                                <th class="text-center">Tipo Papel</th>
                                <th class="text-center">Terminado</th>
                                <th class="text-center">Agrupado</th>
                                <th class="text-center">Archivo(s)</th>
                            </thead>
                            <tbody>
                                <tr class="pt-2 pb-2" v-for="(order,index) in this.data" v-key="index">
                                    <td class="text-center align-middle"> {{ order.tamHojasDesc}} </td>
                                    <td class="text-center align-middle"> {{ order.formatoDesc }} </td>
                                    <td class="text-center align-middle"> {{ order.calidadDesc }}</td>
                                    <td class="text-center align-middle"> {{ order.fazDesc }} </td>
                                    <td class="text-center align-middle"> {{ order.tipoPapelDesc }}</td>
                                    <td class="text-center align-middle"> {{ order.terminadoDesc }} </td>
                                    <td class="text-center align-middle"> {{ this.getDescAgrupado(order.agrupado) }} </td>
                                    <td class="text-center align-middle"> {{ order.files.length }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>  
                </div>
                <div class="modal-footer">
                    <button class="btn lift lift-sm" v-if="this.estado == 'PENDIENTE PAGO'" style="background-color:#259dc7;color:white" type="button" @click="getMercadoPago(this.id)">Pagar en Mercado Pago</button>
                    <button class="btn lift lift-sm" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import store from '@/store/index.js';
    import tioTotoService from '../service/data.service'

    export default {        
        components: {},
        name: 'ModalDetailOrder',
        methods: {
            getDescAgrupado(data){
                if (data == "A"){
                    return "Agrupado";
                } else if (data == "I"){
                    return "Individual";
                } else{
                    return data;
                }
            },
            getMercadoPago: async function (id) {
            try {
                debugger;
                store.dispatch("setSpinner", true);
                const {
                    status,
                    data
                } = await tioTotoService.getLinkMP(id);
                window.location.href = data;
            } catch (error) {
                this.$swal("Tío Toto", error, "info");
            } finally {
                store.dispatch("setSpinner", false);
            }
        },
        },
        computed: {
        },
        props: {
            data: Object,
            id:String,
            estado:String,
        }
    }
    </script>
    
    <style lang="css" scoped>
    .head-order{
        color: rgb(5, 56, 132);
        font-size: 20px;
        background-color: #dbdbdb;
        font-weight:600;
    }
    
    .row-order{
       box-shadow: rgba(33, 40, 50, 0.15) 0px 0.15rem 1.75rem 0px;
       border: 1px solid rgba(33, 40, 50, 0.125);
    }
    .btn-eliminar{
        background-color: #959595 !important;
        border: none;
        position: relative;
        cursor:pointer !important;font-size: 14px;
    }
    
    </style>
    